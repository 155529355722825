import { useState } from 'react'
import axios from 'axios'
import FormData from 'form-data'
import { calculateWer } from '../utils/wer'
import { localeList } from '../utils/static/consts'

export default function Advanced() {
  const [providers, setProviders] = useState([
    {
      name: 'Amazon Transcribe',
      checked: false,
      value: 'transcribe',
      logoUrlClass:
        "rounded-lg inline-flex p-3 text-teal-700 ring-4 ring-white bg-[url('https://ugc.futurelearn.com/uploads/images/aa/be/aabe1a30-a946-4a33-90a5-43dec5429a8a.svg')] bg-no-repeat bg-center",
    },
    {
      name: 'Deepgram',
      checked: false,
      value: 'deepgram',
      logoUrlClass:
        "rounded-lg inline-flex p-3 text-teal-700 ring-4 ring-white bg-[url('https://avatars.githubusercontent.com/u/17422641?s=200&v=4')] bg-no-repeat bg-center bg-cover",
    },
    {
      name: 'Google',
      checked: false,
      value: 'google',
      logoUrlClass:
        "rounded-lg inline-flex p-3 text-teal-700 ring-4 ring-white bg-[url('https://www.gstatic.com/pantheon/images/welcome/supercloud.svg')] bg-no-repeat bg-center",
    },
    {
      name: 'Microsoft Azure',
      checked: false,
      value: 'azure',
      logoUrlClass:
        "rounded-lg inline-flex p-3 text-teal-700 ring-4 ring-white bg-[url('https://swimburger.net/media/fbqnp2ie/azure.svg')] bg-no-repeat bg-center bg-cover",
    },
  ])
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [utterance, setUtterance] = useState('')
  const [expectedUtterance, setExpectedUtterance] = useState('')
  const [useFile, setUseFile] = useState(false)
  const [error, setError] = useState('')
  const [showError, setShowError] = useState(false)
  const [fileInput, setFileInput] = useState('')
  const [locales, setLocales] = useState(localeList)

  const clearLoadingGif = () => {
    setTimeout(() => {
      const closeButton = document.querySelector('#close-loading')
      closeButton.click()
    }, 500)
  }

  const refreshState = () => {
    const form = document.getElementById('fileForm')
    form.reset()
    setUtterance('')
    setExpectedUtterance('')
    setShowResults(false)
    setUseFile(false)
    setProviders(
      providers.map((provider) => {
        provider.checked = false
        return provider
      })
    )
  }

  const selectLocale = (selectedLocale) => {
    let updatedLocales = locales.map((locale) => ({
      ...locale,
      selected: selectedLocale.id === locale.id,
    }))
    setLocales(updatedLocales)
  }

  const compareAccuracy = async () => {
    setShowError(false)
    const formData = new FormData()
    const selectedProviders = providers
      .filter((provider) => provider.checked)
      .map((provider) => provider.value)
    // http://localhost:13200/accuracy/process "https://accuracy-api-dev.bespoken.io/accuracy/process"
    if (!selectedProviders.length || (!useFile && !utterance)) {
      setShowError(true)
      setError(
        'please select a provider and enter a text/URL to be transcribed'
      )
      clearLoadingGif()
      return
    }

    if (useFile && !expectedUtterance) {
      setShowError(true)
      setError(
        'please add expected utterance to compare when using a file or URL'
      )
      clearLoadingGif()
      return
    }
    setLoading(true)
    const selectedLocale =
      locales.find((locale) => !!locale.selected)?.id || 'en-US'
    const actualUtterance = useFile ? expectedUtterance || utterance : utterance
    if (useFile) formData.append('fileupload', fileInput[0])
    formData.append('utterance', actualUtterance)
    formData.append('providers', JSON.stringify(selectedProviders))
    formData.append('locale', selectedLocale)
    const request = await axios
      .post(
        'https://accuracy-api-dev.bespoken.io/accuracy/processAdvanced',
        formData
      )
      .catch((err) => {
        console.log(err)
        setShowError(true)
        setError('oops somethign went wrong while comparing the accuracy')
      })
    let getProccessResults
    if (request && request.data && request.data.jobKey) {
      while (!getProccessResults) {
        const getResult = await axios.get(
          `https://accuracy-api-dev.bespoken.io/accuracy/getResultByKey/${request.data.jobKey}`
        )
        if (
          getResult.data &&
          getResult.data.result &&
          getResult.data.result !== 'file not found'
        )
          getProccessResults = getResult.data.result
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
      setShowResults(true)
    } else {
      refreshState()
    }
    if (getProccessResults) {
      try {
        const resultsWithWER = getProccessResults.map((result) => {
          const errorRateResult = calculateWer(
            expectedUtterance || utterance,
            result?.outputFields?.['ACTUAL TRANSCRIPT'] || ''
          )
          const errorRate = errorRateResult && errorRateResult.wer
          const wordErrorRatePercentage = errorRate.toLocaleString('en', {
            style: 'percent',
            minimumFractionDigits: 2,
          })
          return {
            ...result,
            errorRate,
            wordErrorRatePercentage,
            werData: errorRateResult,
          }
        })
        setResults(resultsWithWER.sort((a, b) => a.errorRate - b.errorRate))
        setShowResults(true)
      } catch (error) {
        setShowError(true)
        setError('oops somethign went wrong while comparing the accuracy')
        clearLoadingGif()
        setLoading(false)
      }
    } else {
      refreshState()
    }
    clearLoadingGif()
    setLoading(false)
  }

  const buttonClass =
    'text-lg font-medium inline-flex items-center rounded border border-transparent bg-bespoken-blue px-2.5 py-1.5 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-bespoken-blue focus:ring-offset-2'
  const fileInputClass =
    'my-4 form-control block w-full px-3 py-1.5 text-base font-normal text-bespoken-blue bg-white bg-clip-padding border border-solid border-bespoken-blue rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
  return (
    <div>
      <div>
        <div
          className={
            showError
              ? 'bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full'
              : 'hidden'
          }
          role="alert"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times-circle"
            className="w-4 h-4 mr-2 fill-current"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
            ></path>
          </svg>
          {error}
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-1 ml-auto text-yellow-900 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-yellow-900 hover:opacity-75 hover:no-underline"
            data-bs-dismiss="alert"
            onClick={() => setShowError(false)}
            aria-label="Close"
          ></button>
        </div>
        <nav aria-label="Progress">
          <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            <li className="relative md:flex md:flex-1">
              <button href="#" className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-bespoken-teal">
                    <span className="text-bespoken-teal">01</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    Step 1: Select the providers
                  </span>
                </span>
              </button>
              <div
                className="absolute top-0 right-0 hidden h-full w-5 md:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>

            <li className="relative md:flex md:flex-1">
              <button
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-bespoken-teal">
                  <span className="text-bespoken-teal">02</span>
                </span>
                <span className="ml-4 text-sm font-medium">
                  Step 2: Select the audio file
                </span>
              </button>
              <div
                className="absolute top-0 right-0 hidden h-full w-5 md:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>

            <li className="relative md:flex md:flex-1">
              <button className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-bespoken-teal">
                    <span className="text-bespoken-teal">03</span>
                  </span>
                  <span className="ml-4 text-sm font-medium">
                    Step 3: Compare Accuracy
                  </span>
                </span>
              </button>
            </li>
          </ol>
        </nav>
        <div
          className={
            showResults
              ? 'hidden'
              : 'visible transition ease-in-out delay-1500 opacity-1'
          }
        >
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-5">
              <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-6">
                <div className="px-4 py-5 sm:p-6">
                  <legend className="text-lg font-medium text-bespoken-blue">
                    Step 1: Select the providers to test
                  </legend>
                  <section aria-labelledby="quick-links-title">
                    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-4 sm:gap-px sm:divide-y-0">
                      {providers.map((provider, i) => (
                        <div
                          key={`providersDiv${i}`}
                          onClick={() =>
                            setProviders(
                              providers.map((item) =>
                                item.name === provider.name
                                  ? {
                                      ...item,
                                      checked: !provider.checked,
                                    }
                                  : item
                              )
                            )
                          }
                          className={
                            provider.checked
                              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-4 ring-2 ring-inset ring-bespoken-orange'
                              : 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-4 hover:ring-2 hover:ring-inset hover:ring-bespoken-blue'
                          }
                        >
                          <div>
                            <span className={provider.logoUrlClass.toString()}>
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              ></svg>
                            </span>
                          </div>
                          <div className="mt-2">
                            <h3 className="text-lg font-medium">
                              <button className="focus:outline-none">
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                ></span>
                                {provider.name}
                              </button>
                            </h3>
                          </div>
                          <span
                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                            aria-hidden="true"
                          >
                            <div className="ml-3 flex h-5 items-center">
                              <input
                                id="person-1"
                                name="person-1"
                                type="checkbox"
                                className="invisible h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>
                  </section>
                  <section>
                    <div className="dropdown mt-4">
                      <button
                        className="dropdown-toggle inline-block px-4 py-1.5 bg-bespoken-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-bespoken-blue hover:shadow-lg focus:bg-bespoken-blue focus:shadow-lg focus:outline-none focus:ring-0 active:bg-bespoken-blue active:shadow-lg transition duration-150 ease-in-out inline-flex items-center whitespace-nowrap"
                        type="button"
                        id="dropdownMenuSmallButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {locales.find((locale) => !!locale.selected)?.name}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="caret-down"
                          className="w-2 ml-2"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                          ></path>
                        </svg>
                      </button>
                      <span className="ml-4 text-bespoken-blue">
                        Select a different locale if needed
                      </span>
                      <ul
                        className="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                        aria-labelledby="dropdownMenuSmallButton"
                      >
                        {locales.map((locale) => (
                          <li key={`locale${locale.id}`}>
                            <button
                              className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                              onClick={() => selectLocale(locale)}
                            >
                              {locale.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </section>
                </div>
              </div>
              <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-6 sm:grid sm:grid-cols-2">
                <div className="px-4 py-5 sm:p-6">
                  <legend className="text-lg font-medium text-bespoken-blue my-6">
                    Step 2: Enter the text you want to compare (uses Google
                    Wavenet voice) or file URL
                  </legend>
                  <textarea
                    rows={4}
                    name="comment"
                    id="message"
                    className={
                      useFile
                        ? 'hidden'
                        : 'p-2 mb-6 block w-full rounded-md border border-bespoken-blue shadow-sm border-bespoken-blue ring-bespoken-blue'
                    }
                    value={utterance}
                    onInput={(e) => setUtterance(e.target?.value)}
                  ></textarea>
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="useFileCheck"
                        aria-describedby="use-a-file-description"
                        name="useFileCheck"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-bespoken-blue focus:ring-bespoken-orange"
                        checked={useFile}
                        onChange={(e) => setUseFile(e.target.checked)}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="comments"
                        className="font-medium text-bespoken-blue"
                      >
                        Or use a file on your computer
                      </label>
                    </div>
                  </div>
                  <div className={useFile ? '' : 'hidden'}>
                    <form id="fileForm">
                      <input
                        className={fileInputClass}
                        type="file"
                        id="fileInput"
                        onChange={(e) => setFileInput(e.target.files)}
                        accept=".wav"
                      />
                      <p className="text-bespoken-blue">
                        Accepted formats: ".wav"
                      </p>
                    </form>
                  </div>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <legend className="text-lg font-medium text-bespoken-blue my-6 2xl:mb-6 xl:mb-12 lg:mb-6 md:mb-12 sm:mb-12">
                    When using a file or URL add here your expected
                    transcription
                  </legend>
                  <textarea
                    rows={4}
                    name="comment"
                    id="expectedUtterance"
                    className="p-2 mb-6 block w-full rounded-md border border-bespoken-blue shadow-sm border-bespoken-blue ring-bespoken-blue"
                    value={expectedUtterance}
                    onInput={(e) => setExpectedUtterance(e.target?.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-hidden bg-white shadow sm:rounded-lg my-6">
            <div className="px-4 py-5 sm:p-6">
              <button
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                disabled={loading}
                onClick={compareAccuracy}
                className={
                  loading
                    ? `cursor-not-allowed ${buttonClass}`
                    : `hover:bg-bespoken-blue/[.9] ${buttonClass}`
                }
              >
                {loading ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  ''
                )}
                Step 3: Compare Accuracy
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            showResults
              ? 'transition overflow-hidden bg-white shadow sm:rounded-lg my-6'
              : 'hidden'
          }
        >
          <div className="px-4 py-5 sm:p-6">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item bg-white border border-gray-200">
                <h2 className="accordion-header mb-0" id="headingOne">
                  <button
                    className="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <legend className="text-lg font-medium text-bespoken-blue">
                      Original Text
                    </legend>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body py-4 px-5">
                    {expectedUtterance || utterance}
                  </div>
                </div>
              </div>
            </div>
            {useFile &&
            results &&
            results.length &&
            results[0].outputFields &&
            results[0].outputFields['ACTUAL UTTERANCE'] ? (
              <div
                className="bg-yellow-100 rounded-lg py-5 px-6 mt-4 text-base text-yellow-700 break-all"
                role="alert"
              >
                <b>
                  The audio from the transcription is{' '}
                  <u>
                    <a
                      target="_blank"
                      className="text-bespoken-blue"
                      href={results[0].outputFields['ACTUAL UTTERANCE']}
                    >
                      available here
                    </a>
                  </u>
                  . It will expire after one-day.
                </b>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="px-4 py-5 sm:p-6">
            <dl className="mt-5 grid grid-cols-4 gap-5 sm:grid-cols-4 lg:grid-cols-4">
              {results.map((result, i) => {
                const providerData = providers.find(
                  (provider) => provider.value === result.outputFields?.PLATFORM
                )
                return (
                  <div
                    key={`resultsDiv${i}`}
                    className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
                  >
                    <dt>
                      <div className="absolute rounded-md">
                        <span
                          className={
                            providerData?.logoUrlClass
                              ? providerData?.logoUrlClass.toString()
                              : ''
                          }
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          ></svg>
                        </span>
                      </div>
                      <p className="ml-16 truncate text-sm font-medium text-bespoken-blue">
                        {providerData?.name}
                      </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                      <p
                        className={
                          result.errorRate < 0.15
                            ? 'text-2xl font-semibold text-green-600'
                            : result.errorRate < 0.35
                            ? 'text-2xl font-semibold text-orange-600'
                            : 'text-2xl font-semibold text-red-600'
                        }
                      >
                        {result.wordErrorRatePercentage}
                      </p>
                      <p className="ml-2 flex items-baseline text-sm font-semibold">
                        WER
                      </p>
                    </dd>
                    <div>
                      <div className="mb-6">
                        <span className="rounded-full bg-green-600 p-2">
                          Insertions
                        </span>{' '}
                        <span className="rounded-full bg-red-500 p-2">
                          Deletions
                        </span>
                      </div>
                      {result.werData.diff.map((part, i) => {
                        const colorClass = part.added
                          ? 'px-1 bg-green-600'
                          : part.removed
                          ? 'px-1 bg-red-500'
                          : ''
                        return (
                          <span key={`werDataSpan${i}`} className={colorClass}>
                            {part.value}
                          </span>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </dl>
            <button
              className="mt-6 text-lg font-medium inline-flex items-center rounded border border-transparent bg-bespoken-blue px-2.5 py-1.5 text-white shadow-sm hover:bg-bespoken-blue/[.9] focus:outline-none focus:ring-2 focus:ring-bespoken-blue focus:ring-offset-2"
              onClick={refreshState}
            >
              Try it again
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="staticBackdrop"
        tabIndex={-1}
        aria-labelledby="exampleModalCenterTitle"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-modal="true"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content bg-loading-llama bg-no-repeat bg-cover border-none shadow-lg flex flex-col w-full pointer-events-auto bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-md font-medium leading-normal h-96 p-8"
                id="exampleModalLabel"
              >
                This proccess will take a while, you might want to get a cup of
                coffee on the meantime
              </h5>
              <button
                type="button"
                className="btn-close hidden"
                id="close-loading"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
