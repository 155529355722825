import { Outlet } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div id="MainContainer" className="container mx-auto">
      <header>
        <div className="relative bg-white">
          <div className="flex w-full items-center justify-between py-6 md:justify-start md:space-x-10">
            <div className="flex-1 justify-start lg:w-0 lg:flex-1">
              <div className="pos">
                <img
                  className="h-8 sm:h-14 float-left"
                  src="https://bespoken.io/wp-content/uploads/2019/07/Logo-original.png"
                  alt=""
                />
                <span className="grid pl-2 text-justify">
                  The Bespoken ASR Accuracy Benchmark allows for evaluating the
                  performance of a variety of ASR engines. Select providers and
                  audio file below to compare word error rate for each scenario.
                  Additionally, to evaluate other providers not listed here or
                  test with custom audio samples, contact us via the form on the
                  right
                </span>
              </div>
            </div>
            <nav className="hidden space-x-10 md:flex">
              <a
                href="https://bespoken.io/pricing/"
                target="_blank"
                rel="noreferrer"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Pricing
              </a>
              <a
                href="https://bespoken.io/#tools"
                target="_blank"
                rel="noreferrer"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Tools
              </a>
              <a
                href="https://read.bespoken.io/"
                target="_blank"
                rel="noreferrer"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Docs
              </a>
            </nav>
          </div>
        </div>
      </header>
      <Outlet />
    </div>
  );
}

export default App;
