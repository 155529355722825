import {calculate} from './benchmark';

const options = {
    removespeakers: true,
    removeannotations: true,
    removewhitespaces: true,
    removequotes: true,
    removedashes: true,
    removepunctuations: true,
    lowercase: true
}

export const calculateWer = (original, generated) => {
    const result = calculate(original, generated, {
        removeSpeakerStarters: options.removespeakers,
        removeAnnotations: options.removeannotations,
        removeExtraWhiteSpaces: options.removewhitespaces,
        removeQuotes: options.removequotes,
        removeDashes: options.removedashes,
        removePunctuations: options.removepunctuations,
        lowerCase: options.lowercase
    });
    return result;
}